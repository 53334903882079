/***************************/
/* GLOBAL */
/***************************/

:root {
  --bg-color-main: #00c38d;
  --gray-dark: #343d3a;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: 'Open Sans', 'Lora', Helvetica, Arial, sans-serif;
  line-height: 1;
  font-weight: 400;
  color: var(--gray-dark);
  overflow-x: hidden;
}

/***************************/
/* GENERAL */
/***************************/

.container {
  max-width: 120rem;
  padding: 0 3.2rem;
  margin: 0 auto;
}

.heading-primary,
.heading-secondary,
.heading-tertiary {
  font-family: 'Lora', 'Open Sans', sans-serif;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.heading-primary {
  font-size: 6.192rem;
  line-height: 1.2;
  margin-bottom: 8rem;
}

.heading-secondary {
  font-size: 5.16rem;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 9.6rem;
}

.heading-tertiary {
  font-size: 3.583rem;
  line-height: 1.2;
  margin-bottom: 4rem;
}

/***************************/
/* BUTTONS AND LINKS */
/***************************/

.btn,
.btn:link,
.btn:visited {
  display: inline-block;
  text-decoration: none;
  font-size: 1.728rem;
  font-weight: 600;
  padding: 1.6rem 3.2rem;
  border-radius: 9px;
  border: none;
  cursor: pointer;
  font-family: inherit;
  transition: all 0.3s;
}

.btn,
.btn--solid:link,
.btn--solid:visited {
  background-color: var(--gray-dark);
  color: #fff;
}

.btn--solid:hover,
.btn--solid:active {
  background-color: #fff;
  box-shadow: inset 0 0 0 2px var(--gray-dark);
  color: var(--gray-dark);
}

.btn--outline:link,
.btn--outline:visited {
  background: none;
  color: var(--gray-dark);
  box-shadow: inset 0 0 0 2px var(--gray-dark);
}

.btn-icon {
  height: 2rem;
  width: 2rem;
  color: currentColor;
}

/***************************/
/* KEYFRAMES */
/***************************/

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-9%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.opacity-zero {
  opacity: 0;
}

.fade-in {
  animation: 1s ease-out 0s 1 fadeIn;
}
